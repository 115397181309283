import { useDisclosure, useOutsideClick } from '@chakra-ui/react'
import { searchUser } from 'helpers/queryFunctions'
import Link from 'next/link'
import { FC, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { User } from '../interfaces/interfaces'
import { shortenAddressWithNoDots } from '../utils'
import { Avatar } from './core'
import { SearchIcon } from './icons'
import StyledLoading from './StyledLoading'

const SearchBar: FC = () => {
	const [searchText, setSearchText] = useState('')
	const { data: searchedUsersList, isLoading: isSearchLoading } = useQuery(['user_search', searchText], searchUser, {
		enabled: Boolean(searchText),
	})

	const top10 = searchedUsersList?.slice(0, 10)
	const searchedUsers = isSearchLoading ? (
		<div className="flex justify-center py-4 rounded-full mt-1 max-w-full bg-color-0 shadow-none">
			<StyledLoading />
		</div>
	) : searchedUsersList?.length ? (
		<div className="rounded-3xl overflow-hidden mt-1 bg-color-0 shadow-none">
			{top10.map((user, i) => (
				<SearchResults key={i} user={user} setSearchText={setSearchText} />
			))}
		</div>
	) : searchText !== '' ? (
		<div className="rounded-full mt-1 bg-color-0 shadow-none">
			<div className="mb-1 p-4 w-full rounded-3xl text-color-8">No Search Results</div>
		</div>
	) : null

	const { isOpen, onOpen, onClose } = useDisclosure()
	const searchRef = useRef()
	useOutsideClick({
		ref: searchRef,
		handler: onClose,
	})

	return (
		<div ref={searchRef} className="w-full lg:w-1/2 relative">
			<div
				className="flex lg:flex-shrink w-full px-4 h-10 mt-8 lg:mt-0 justify-center items-center border rounded-full border-color-3"
				onClick={onOpen}
			>
				<SearchIcon className="fill-color-6 w-4 h-4" />
				<input
					type="text"
					className="w-full h-full bg-transparent text-color-6 focus:opacity-100 px-2 py-2 focus:outline-none rounded-full font-normal leading-none placeholder-color-6 text-sm 2xl:text-base"
					placeholder="Search for creators or communities"
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
				/>
			</div>
			<div className="w-full absolute">{isOpen && searchedUsers}</div>
		</div>
	)
}

interface Props {
	user: User
	setSearchText: (num: string) => void
}

const SearchResults: FC<Props> = ({ user, setSearchText, ...props }: Props) => (
	<div
		{...props}
		className="px-4 py-2 w-full search-item hover:bg-color-2 smooth-hover cursor-pointer"
		onClick={() => setSearchText('')}
	>
		<Link href={`/${user.user_name || user.wallet_address}`}>
			<div className="flex items-center">
				<Avatar gradient1={user.gradient_1} gradient2={user.gradient_2} profilePicUrl={user?.profile_pic_url} />
				<div className="ml-3 flex flex-col overflow-hidden dark:text-dark-8">
					<div className="flex align-center dark:text-dark-8">
						<p className="truncate">{user.user_name || shortenAddressWithNoDots(user.wallet_address)}</p>{' '}
						{user.is_verified && (
							<span className="flex items-center">
								<img className="w-4 ml-2" src="/images/new-verify.svg" alt="" />
							</span>
						)}
					</div>
					{user.name && <p className="font-medium truncate">{user.name}</p>}
				</div>
			</div>
		</Link>
	</div>
)

export default SearchBar
