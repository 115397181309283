import { useAppSetters } from '@components/contexts/AppProvider'
import { Confetti } from '@components/core'
import useComponentVisible from '@hooks/useComponentVisible'
import useWindowSize from '@hooks/useWindowSize'
import { FC, useEffect } from 'react'
import BottomNavbar from './BottomNavbar'
import Footer from './Footer'
import Header from './Header'
import SEO from './SEO'
import SideNavbar from './SideNavbar'

type Props = {
	noBanner?: boolean
	title?: string
	description?: string
	metaImage?: string
}

const Layout: FC<Props> = ({ children, noBanner, title, description, metaImage }) => {
	const [, width] = useWindowSize()
	const isMobile = width < 1024
	const [ref, showSidebar, setShowSidebar] = useComponentVisible(true)

	useEffect(() => {
		setShowSidebar(!isMobile)
	}, [isMobile, setShowSidebar])

	const toggleSidebar = (): void => {
		setShowSidebar((showSidebar) => !showSidebar)
	}

	const { setConfettiRef } = useAppSetters()

	return (
		<>
			<SEO image={metaImage} title={title} description={description} />
			<main className="flex flex-row min-h-screen bg-color-1">
				<SideNavbar ref={isMobile ? ref : undefined} show={showSidebar} />
				<div className="flex flex-col lg:pl-[60px] w-full min-h-screen">
					<Header noBanner={noBanner} />
					{children}
					<Footer />
					<BottomNavbar toggleSidebar={toggleSidebar} />
				</div>
				<Confetti setRef={setConfettiRef} />
			</main>
		</>
	)
}

export default Layout
