import { useAppContext } from '@components/app-context'
import { useAppSetters } from '@components/contexts/AppProvider'
import * as Sentry from '@sentry/nextjs'
import { WalletType } from 'lib/wallet'
import { MagicUserMetadata } from 'magic-sdk'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { logoutApi } from '../helpers/queryFunctions'

type UseLogoutArgs = {
	onLogout?: () => void
}

const useLogout = ({ onLogout }: UseLogoutArgs = {}): UseMutationResult<
	any,
	unknown,
	void,
	{
		previousUser: Partial<MagicUserMetadata> | null
	}
> => {
	const queryClient = useQueryClient()
	const { web3Modal, walletProvider } = useAppContext()
	const { setWalletProvider, setChainId } = useAppSetters()

	return useMutation(logoutApi, {
		onMutate: async () => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries('current_user')

			// Snapshot the previous value
			const previousUser = queryClient.getQueryData('current_user')
			const previousProfile = queryClient.getQueryData('current_profile')

			// Optimistically update to the new value
			queryClient.setQueryData('current_user', () => null)
			queryClient.setQueryData('current_profile', () => null)
			Sentry.configureScope((scope) => scope.setUser(null))

			// Return a context object with the snapshotted value
			return { previousUser, previousProfile }
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, _, context) => {
			Sentry.captureException(err)
			queryClient.setQueryData('current_user', context.previousUser)
			queryClient.setQueryData('current_profile', context.previousProfile)
		},
		// Always refetch after error or success:
		onSettled: async () => {
			queryClient.invalidateQueries('current')

			if (window.localStorage) {
				window.localStorage.removeItem(WalletType.WalletConnect)
				window.localStorage.removeItem(WalletType.WalletConnectMobile)
			}
		},
		onSuccess: async () => {
			try {
				await (walletProvider.provider as any).disconnect()
			} catch (error) {
				//
			}
			if (web3Modal) {
				web3Modal.clearCachedProvider()
				setWalletProvider(null)
				setChainId(0)
				// also remove gnosis if any
			}

			if (onLogout) onLogout()
		},
	})
}

export default useLogout
