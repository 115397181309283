import clsx from 'clsx'
import { FC } from 'react'

const StyledLoading: FC<{ className?: string }> = ({ className = 'h-8 w-8' }) => {
	return (
		<div className="w-full flex items-center justify-center">
			<div
				className={clsx(
					className,
					'ease-linear rounded-full border-4 border-t-4 border-color-2 border-t-color-8 styled-loading'
				)}
			></div>
		</div>
	)
}

export default StyledLoading
