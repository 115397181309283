import { useConnectWalletContext } from '@components/contexts/ConnectWalletProvider'
import { Button } from '@components/core'
import { CoinviseLogoIcon, DiscordIcon, GitHubIcon, MirrorIcon, TwitterIcon, WalletIcon } from '@components/icons'
import useCurrentProfile from '@hooks/useCurrentProfile'
import useWindowSize from '@hooks/useWindowSize'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

const ExternalLink: FC<{ link: string; text: string }> = ({ link, text }) => {
	return (
		<a href={link} className="text-[13px] font-semibold text-color-7 hover:text-color-9">
			{text}
		</a>
	)
}

const SocialButton: FC<{ link: string }> = ({ link, children }) => {
	return (
		<a href={link} className="w-8 h-8 rounded-full bg-color-2 shrink-0 flex justify-center items-center">
			{children}
		</a>
	)
}

const socialLinks = [
	{
		key: 'Discord',
		link: 'https://discord.gg/w9YFJU8SGz',
		jsx: <DiscordIcon className="fill-color-6 w-4 h-4" />,
	},
	{
		key: 'Twitter',
		link: 'https://twitter.com/CoinviseCo',
		jsx: <TwitterIcon className="fill-color-6 w-4 h-4" />,
	},
	{
		key: 'Content',
		link: 'https://coinvise.mirror.xyz/',
		jsx: <MirrorIcon className="fill-color-6 w-4 h-4" />,
	},
	{
		key: 'GitHub',
		link: 'https://github.com/coinvise',
		jsx: <GitHubIcon className="fill-color-6 w-4 h-4" />,
	},
]

const infoLinks = {
	Manifesto: 'https://www.notion.so/coinvise/Coinvise-Manifesto-6680623ac3bb4590a0894d0419054bea',
	About: 'https://coinvise.notion.site/About-5fb7ccf93bbe4257bde71a5be73537be',
	Wiki: 'https://wiki.coinvise.co/',
	Jobs: 'https://jobs.coinvise.co/',
	'Terms & Privacy': 'https://drive.google.com/file/d/1bCskQ1Tymf_HRSXxj-H3carkyXfgRKdh/view',
}

const year = new Date().getFullYear()

const ConnectWalletBar: FC = () => {
	const [, width] = useWindowSize()
	const isMobile = width < 1024
	const { onOpen: openConnectWalletModal } = useConnectWalletContext()

	return (
		<div className="z-[1] bg-gradient1 lg:bottom-0 fixed w-full h-[90px] lg:h-[80px] px-6 xl:px-24">
			<div className="h-full grid grid-flow-col items-center">
				<CoinviseLogoIcon className="w-[100px] hidden lg:flex xl:ml-[60px] cursor-pointer fill-white" />
				<div className="flex flex-row justify-end items-center gap-5">
					<span className="text-center text-[15px] lg:text-[18px] text-white font-normal">
						Connect Your Wallet to Login/Signup
					</span>
					<Button
						onClick={openConnectWalletModal}
						size="sm"
						variant="outlined"
						className={clsx(isMobile && 'bg-transparent border-2 border-white text-white')}
					>
						<div className="flex flex-row items-center gap-2">
							<span>Connect Wallet</span>
							<WalletIcon className={clsx(isMobile && 'fill-white', 'w-3 h-3')} />
						</div>
					</Button>
				</div>
			</div>
		</div>
	)
}

const Footer: FC = () => {
	const [, width] = useWindowSize()
	const isMobile = width < 1024
	const router = useRouter()
	const { data: profile } = useCurrentProfile()

	// the pages to show the connect wallet bar
	const pagesWithConnectWalletBar = ['quest', 'token', 'vesting', 'claim', 'airdrop', 'memberships']
	// show the connect wallet bar if user is not logged in and is on one of the desginated pages
	const showConnectWalletBar =
		!profile && pagesWithConnectWalletBar.findIndex((page) => router.route.includes(page)) > -1
	return (
		<>
			{showConnectWalletBar && <ConnectWalletBar />}
			<div
				className={clsx(
					'mt-auto lg:bg-color-1 w-full lg:h-[64px] mb-[60px] lg:mb-0 mx-auto px-12 lg:px-4 xl:px-24 py-16 lg:py-0',
					showConnectWalletBar && 'lg:mb-[80px]'
				)}
			>
				<div className="h-full grid grid-flow-row lg:grid-flow-col gap-5">
					<div className="flex flex-col h-full lg:flex-row justify-center md:justify-start items-center gap-5">
						<Link href="/dashboard" passHref>
							<a>
								<CoinviseLogoIcon className="xl:ml-[60px] w-[100px] cursor-pointer fill-color-3 lg:fill-color-6 hover:fill-[url(#coinvise_logo_gradient)]" />
							</a>
						</Link>
						{isMobile ? (
							<div className="flex flex-row gap-5">
								{socialLinks.map((social) => (
									<SocialButton key={social.key} link={social.link}>
										{social.jsx}
									</SocialButton>
								))}
							</div>
						) : (
							socialLinks.map((social) => <ExternalLink key={social.key} link={social.link} text={social.key} />)
						)}
					</div>
					<div className="flex flex-wrap lg:flex-row items-center justify-center lg:justify-end gap-5">
						{Object.entries(infoLinks).map(([name, link]) => (
							<ExternalLink key={name} link={link} text={name} />
						))}
						<span className="w-full flex justify-center lg:w-max text-[11px] font-normal text-color-6">
							© {year} Coinvise by TokenFi Inc
						</span>
					</div>
				</div>
			</div>
		</>
	)
}

export default Footer
