import axios from 'axios'
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds'
import { useQuery, UseQueryResult } from 'react-query'
import useCurrentUser from '../useCurrentUser'

const getHasUnreadNotifications = (): Promise<boolean> =>
	axios.get('/api/notifications/has_unread', { withCredentials: true }).then((res) => res.data.hasUnread)

const useHasUnreadNotifications = (): UseQueryResult<boolean> => {
	const { data: user } = useCurrentUser()

	return useQuery('has_unread_notification', getHasUnreadNotifications, {
		enabled: Boolean(user),
		staleTime: minutesToMilliseconds(0.5), // 30 sec
		refetchInterval: minutesToMilliseconds(0.5), // After 30 sec it refetches to check if data is changed
		refetchOnWindowFocus: true,
	})
}

export default useHasUnreadNotifications
