import { HamburgerIcon, SearchIcon } from '@components/icons'
import SearchBar from '@components/SearchBar'
import useComponentVisible from '@hooks/useComponentVisible'
import useLockBodyScroll from '@hooks/useLockBodyScroll'
import clsx from 'clsx'
import Link from 'next/link'
import { FC, forwardRef, PropsWithChildren } from 'react'

const BottomDrawer = forwardRef<HTMLDivElement, PropsWithChildren<{ show: boolean }>>(({ show, children }, ref) => {
	return (
		<div
			ref={ref}
			className={clsx(
				show ? 'translate-0 lg:hidden' : 'translate-y-full',
				'bottom-0 transform transition duration-300 fixed h-full w-full bg-color-1 rounded-t-2xl flex-col p-5',
				'text-color-6 font-normal text-[15px]'
			)}
		>
			{children}
		</div>
	)
})

BottomDrawer.displayName = 'BottomDrawer'

const GradientPortalIcon: FC = () => (
	<svg width="32" height="32" className="fill-[url(#gra)]" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
		<path d="M27.3006 4.60681C24.2891 1.66236 20.2474 0.00950544 16.0356 4.08708e-05C11.8239 -0.0094237 7.77476 1.62525 4.75006 4.55613C3.25866 5.99404 2.07261 7.71799 1.26284 9.62486C0.453074 11.5317 0.0362369 13.5823 0.0372639 15.654V31.11H31.9627V15.5527C31.9644 13.508 31.5522 11.4842 30.7509 9.60301C29.9497 7.72186 28.776 6.02235 27.3006 4.60681ZM13.2635 28.5762V16.1101C13.2765 15.3884 13.569 14.6999 14.0794 14.1895C14.5898 13.6791 15.2783 13.3866 16 13.3736C16.3589 13.3733 16.7142 13.4448 17.0449 13.5841C17.3756 13.7234 17.6751 13.9275 17.9257 14.1844C18.1874 14.4313 18.3947 14.7301 18.5343 15.0617C18.674 15.3933 18.7428 15.7504 18.7365 16.1101V28.5762H13.2635ZM19.75 12.4108C19.2641 11.9159 18.6845 11.5228 18.045 11.2544C17.4054 10.9861 16.7189 10.8479 16.0253 10.8479C15.3318 10.8479 14.6452 10.9861 14.0057 11.2544C13.3662 11.5228 12.7866 11.9159 12.3007 12.4108C11.8004 12.8877 11.403 13.4619 11.1328 14.0981C10.8627 14.7342 10.7255 15.419 10.7298 16.1101V28.5762H7.99328V15.8567C8.14737 13.8361 9.05721 11.9477 10.5415 10.568C12.0257 9.18821 13.9754 8.41843 16.0019 8.41203C18.0284 8.40564 19.9829 9.16311 21.4759 10.5335C22.9688 11.9038 23.8905 13.7864 24.0574 15.8061V28.5762H21.2702V16.1101C21.2725 15.4238 21.1393 14.7438 20.8785 14.1091C20.6176 13.4743 20.2341 12.8972 19.75 12.4108ZM29.4289 28.5762H26.5911V15.8061C26.5647 13.0187 25.4457 10.353 23.4747 8.38202C21.5037 6.411 18.838 5.292 16.0507 5.26558C13.2417 5.27084 10.5476 6.38124 8.55071 8.35678C7.56565 9.34077 6.78531 10.5102 6.25474 11.7975C5.72417 13.0848 5.45388 14.4644 5.45952 15.8567V28.5762H2.57103V15.654C2.57487 13.9223 2.92675 12.209 3.60577 10.6159C4.28479 9.02282 5.27708 7.58248 6.5237 6.38044C9.06932 3.89378 12.4921 2.51009 16.0507 2.52911C19.5844 2.5602 22.9734 3.93753 25.527 6.38044C26.7522 7.57482 27.7281 9.00065 28.3979 10.5752C29.0678 12.1497 29.4182 13.8416 29.4289 15.5527V28.5762Z" />
		<defs>
			<linearGradient id="gra" x1="31.2808" y1="-4.43027" x2="-1.39684" y2="-2.59493" gradientUnits="userSpaceOnUse">
				<stop stopColor="#E8B05D" />
				<stop offset="0.28125" stopColor="#ED6F4D" />
				<stop offset="0.807292" stopColor="#7765CB" />
				<stop offset="1" stopColor="#4F81E2" />
			</linearGradient>
		</defs>
	</svg>
)

const BottomNavbar: FC<{ toggleSidebar: () => void }> = ({ toggleSidebar }) => {
	const [ref, showSearchbar, setShowSearchbar] = useComponentVisible(false)
	const toggleSearchbar = (): void => {
		setShowSearchbar((show) => !show)
	}

	useLockBodyScroll(showSearchbar)

	return (
		<>
			<div className={clsx(showSearchbar ? 'lg:hidden' : 'hidden', 'fixed z-10 h-full w-full bg-black opacity-75')} />
			<div ref={ref} className="z-10 fixed bottom-0 w-full flex lg:hidden">
				<BottomDrawer show={showSearchbar}>
					<SearchBar />
				</BottomDrawer>
				<div className="z-10 flex w-full bg-color-2 h-[60px] justify-between items-center px-5 sm:px-16">
					<button onClick={toggleSidebar}>
						<HamburgerIcon className="fill-color-6 w-8 h-8" />
					</button>
					<Link href="/dashboard" passHref>
						<a>
							<GradientPortalIcon />
						</a>
					</Link>
					<button onClick={toggleSearchbar}>
						<SearchIcon className="fill-color-6 w-5 h-5" />
					</button>
				</div>
			</div>
		</>
	)
}

export default BottomNavbar
