import useCurrentUser from '@hooks/useCurrentUser'
import { getPendingAirdrops } from 'helpers/queryFunctions'
import { AirdropUniqueLink } from 'interfaces/interfaces'
import { useQuery, UseQueryResult } from 'react-query'
import { pendingAirdropsSettings } from 'utils/rq-settings'

const usePendingAirdrops = (chainId: string): UseQueryResult<AirdropUniqueLink[]> => {
	const { data: user } = useCurrentUser()

	return useQuery(['pending-airdrops', chainId], getPendingAirdrops, {
		...pendingAirdropsSettings,
		enabled: Boolean(user),
	})
}

export default usePendingAirdrops
