import { Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react'
import { useAppContext } from '@components/app-context'
import ChooseGnosisSafe from '@components/connect-wallet/connectors/ChooseGnosisSafe'
import useAddOrChangeChain from '@hooks/useAddOrChangeChain'
import useBalanceString from '@hooks/useBalanceString'
import useCurrentProfile from '@hooks/useCurrentProfile'
import useCurrentUser from '@hooks/useCurrentUser'
import useLocalStorage from '@hooks/useLocalStorage'
import useLogout from '@hooks/useLogout'
import clsx from 'clsx'
import { isProduction, LocalStorage } from 'helpers/constants'
import { checkIsEth, getChainBlockExplorer, getUsername } from 'helpers/utils'
import { WalletType } from 'lib/wallet'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import Link from 'next/link'
import { FC, MouseEventHandler, useMemo, useState } from 'react'
import { shortenAddress } from 'utils'
import { FixedImageWrapper, ImageHoverSwitch } from '../../common/ImageWrapper'

interface DropdownRowProps {
	href?: string
	openExternal?: boolean
	onClick?: MouseEventHandler
	text: string
	image: { lightHover: string; darkHover: string; light: string; dark: string }
	size?: number
	gnosis?: boolean
}

/**
 *
 * @returns The Rows of the Dropdown Element
 */
const DropdownRow: FC<DropdownRowProps> = ({ href, onClick, image, text, size, gnosis, openExternal = false }) => {
	const [hover, setHover] = useState(false)

	const content = useMemo(
		() => (
			<div
				className="flex items-center px-4 py-3 gap-x-2 hover:bg-color-0 w-full smooth-hover cursor-pointer"
				onClick={onClick}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<ImageHoverSwitch
					image={image}
					size={size ?? 20}
					customHover={hover}
					setCustomHover={setHover}
					className="mt-1"
				/>
				<h1
					className={clsx(
						'text-base font-normal tracking-[0.01em] smooth-hover',
						hover ? (gnosis ? 'text-gnosis' : 'text-color-8') : 'text-color-6'
					)}
				>
					{text}
				</h1>
			</div>
		),
		[gnosis, hover, image, onClick, size, text]
	)

	if (href)
		return (
			<Link href={href} passHref>
				{openExternal ? (
					<a target="_blank" rel="noopener noreferrer">
						{content}
					</a>
				) : (
					content
				)}
			</Link>
		)

	return content
}

export const ProfileDropdown: FC = () => {
	const { data: userProfile } = useCurrentProfile()
	const { theme } = useTheme()
	return (
		<Menu placement="auto" closeOnBlur closeOnSelect isLazy>
			<MenuButton>
				<div
					className="h-10 w-10 rounded-full my-auto"
					style={{
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						...(userProfile.user.profile_pic_url
							? { backgroundImage: `url(${userProfile.user.profile_pic_url})` }
							: {
									background: `linear-gradient(${userProfile.user.gradient_1}, ${userProfile.user.gradient_2})`,
							  }),
					}}
				></div>
			</MenuButton>
			<MenuList
				// TODO: Change this to a better alternative or Maybe use Chakra Theme
				bg={theme === 'light' ? '#E8E8E8' : '#242336'}
				pt="0"
				pb="0"
				borderWidth={0}
				borderRadius="12px"
				className="rounded-xl overflow-hidden divide-y divide-color-4 bg-color-2"
			>
				<DropdownRow
					image={{
						lightHover: '/images/Header/light-hover-view-profile.svg',
						darkHover: '/images/Header/dark-hover-view-profile.svg',
						light: '/images/Header/light-view-profile.svg',
						dark: '/images/Header/dark-view-profile.svg',
					}}
					text="View profile"
					href={userProfile ? `/` + getUsername(userProfile.user, true) : '/'}
				/>
				<DropdownRow
					image={{
						lightHover: '/images/Header/light-hover-edit.svg',
						darkHover: '/images/Header/dark-hover-edit.svg',
						light: '/images/Header/light-edit.svg',
						dark: '/images/Header/dark-edit.svg',
					}}
					size={17}
					text="Edit profile"
					href="/profile"
				/>
			</MenuList>
		</Menu>
	)
}

/**
 *
 * @returns Dropdown to disconnect wallet or change Network
 */
export const WalletDropdown: FC = () => {
	const logout = useLogout()
	const { chainId } = useAppContext()
	const addChain = useAddOrChangeChain()
	const { data: balance } = useBalanceString()
	const { data: user } = useCurrentUser()
	const { theme } = useTheme()
	const { web3Modal } = useAppContext()

	const walletImage = useMemo<string>(() => {
		if (!web3Modal) return '' //TODO:

		switch (web3Modal.cachedProvider as WalletType) {
			case WalletType.Metamask:
				return '/images/Wallets/metamask.svg'

			case WalletType.WalletConnect:
			case WalletType.WalletConnectMobile:
				return '/images/Wallets/wallet-connect.svg'

			case WalletType.Coinbase:
				return '/images/Wallets/coinbase-wallet.svg'

			default:
				return ''
		}
	}, [web3Modal])

	const isEth = [1, 3].includes(chainId)
	return (
		<Menu placement="auto" closeOnBlur closeOnSelect isLazy>
			<MenuButton>
				<div className="w-full h-full flex items-center">
					<Image src={walletImage ?? '/images/Wallets/metamask.svg'} width={19} height={19} />
				</div>
			</MenuButton>
			<MenuList
				bg={theme === 'light' ? '#E8E8E8' : '#242336'}
				pt="0"
				pb="0"
				borderWidth={0}
				borderRadius="12px"
				className="rounded-xl overflow-hidden divide-y divide-color-4 bg-color-2"
			>
				<div className="flex justify-between items-center px-4 py-3 min-w-[11rem]">
					<div className="flex items-center rounded border border-color-4 px-1 py-0">
						{/* TODO: render something else if chainId === 0; it means wallet has been disconnected */}
						{isEth ? (
							<FixedImageWrapper
								lightImage="/images/Header/light-eth.svg"
								darkImage="/images/Header/dark-eth.svg"
								height={16}
								width={16}
								className="mt-1"
							/>
						) : (
							<FixedImageWrapper
								lightImage="/images/Header/light-polygon.svg"
								darkImage="/images/Header/dark-polygon.svg"
								height={16}
								width={16}
								className="mt-1"
							/>
						)}
						<h1 className="font-semibold text-sm tracking-wider text-color-6 ml-1">
							{balance} {isEth ? 'ETH' : 'MATIC'}
						</h1>
					</div>
					<Link href={user && `${getChainBlockExplorer(chainId)}address/${user.publicAddress}`}>
						<a target="_blank" rel="noopener noreferrer">
							<ImageHoverSwitch
								image={{
									lightHover: '/images/Header/light-hover-external-link.svg',
									darkHover: '/images/Header/dark-hover-external-link.svg',
									light: '/images/Header/light-external-link.svg',
									dark: '/images/Header/dark-external-link.svg',
								}}
								size={17}
							/>
						</a>
					</Link>
				</div>
				<DropdownRow
					image={{
						lightHover: '/images/Header/light-hover-sign-out.svg',
						darkHover: '/images/Header/dark-hover-sign-out.svg',
						light: '/images/Header/light-sign-out.svg',
						dark: '/images/Header/dark-sign-out.svg',
					}}
					text="Sign out"
					size={15}
					onClick={async () => {
						await logout.mutateAsync()
					}}
				/>

				{isEth ? (
					<>
						<DropdownRow
							image={{
								lightHover: '/images/polygon.svg',
								darkHover: '/images/polygon.svg',
								light: '/images/Header/light-polygon.svg',
								dark: '/images/Header/dark-polygon.svg',
							}}
							text="Matic Network"
							size={15}
							onClick={() => addChain(137)}
						/>
						{!isProduction && (
							<DropdownRow
								image={{
									lightHover: '/images/polygon.svg',
									darkHover: '/images/polygon.svg',
									light: '/images/Header/light-polygon.svg',
									dark: '/images/Header/dark-polygon.svg',
								}}
								text="Mumbai Network"
								size={15}
								onClick={() => addChain(80001)}
							/>
						)}
					</>
				) : (
					<>
						<DropdownRow
							// TODO:Change Images with hover change Icons for eth
							image={{
								lightHover: '/images/eth.svg',
								darkHover: '/images/eth.svg',
								light: '/images/Header/light-eth.svg',
								dark: '/images/Header/dark-eth.svg',
							}}
							text="Mainnet"
							size={15}
							onClick={() => addChain(1)}
						/>
						{!isProduction && (
							<DropdownRow
								// TODO:Change Images with hover change Icons for eth
								image={{
									lightHover: '/images/eth.svg',
									darkHover: '/images/eth.svg',
									light: '/images/Header/light-eth.svg',
									dark: '/images/Header/dark-eth.svg',
								}}
								text="Ropsten"
								size={15}
								onClick={() => addChain(3)}
							/>
						)}
					</>
				)}
			</MenuList>
		</Menu>
	)
}

export const GnosisSafeDropdown: FC = () => {
	const { theme } = useTheme()
	// const { isOpen, onOpen, onClose: onClose, onToggle } = useDisclosure()
	const { isOpen: isGnosisModalOpen, onOpen: onGnosisModalOpen, onClose: onGnosisModalClose } = useDisclosure()

	const { chainId, safeSdk } = useAppContext()
	const [gnosisAddress, , removeGnosis] = useLocalStorage(`${LocalStorage.GNOSIS}__${chainId}`, null)
	const isEth = checkIsEth(chainId)
	const { data: balance } = useBalanceString({ gnosis: true })

	return (
		<>
			<ChooseGnosisSafe isOpen={isGnosisModalOpen} onClose={onGnosisModalClose} />
			{/* <Menu isOpen={isOpen} placement="auto"> */}
			<Menu placement="auto" closeOnBlur closeOnSelect isLazy>
				{/* TODO: Choose what we want */}
				{/* <MenuButton onMouseEnter={onOpen} onMouseLeave={onClose}> */}
				<MenuButton>
					<div className="flex items-center">
						<Image src="/images/Wallets/gnosis-safe-home.svg" width={19} height={19} />
						<h1 className="text-base tracking-[0.01em] font-normal text-color-6 pl-2 pr-4 py-2 border-r border-color-4 rounded-full">
							{safeSdk && gnosisAddress ? shortenAddress(gnosisAddress, 4) : 'Gnosis Safe'}
						</h1>
					</div>
				</MenuButton>
				<MenuList
					// onMouseEnter={onOpen}
					// onMouseLeave={onClose}
					bg={theme === 'light' ? '#E8E8E8' : '#242336'}
					pt="0"
					pb="0"
					borderWidth={0}
					borderRadius="12px"
					className="rounded-xl overflow-hidden divide-y divide-color-4 bg-color-2 min-w-[10rem]"
				>
					{safeSdk ? (
						<>
							<div className="flex justify-between items-center px-4 py-3">
								<div className="flex items-center rounded bg-gnosis px-1 py-0">
									{isEth ? (
										<Image src="/images/eth.svg" height={16} width={16} />
									) : (
										<Image src="/images/polygon.svg" height={16} width={16} />
									)}
									<h1 className="font-semibold text-sm tracking-wider text-color-2 ml-1">
										{balance} {isEth ? 'ETH' : 'MATIC'}
									</h1>
								</div>
								<Link href={`${getChainBlockExplorer(chainId)}address/${gnosisAddress}`}>
									<a target="_blank" rel="noopener noreferrer">
										<ImageHoverSwitch
											image={{
												lightHover: '/images/Header/light-hover-external-link.svg',
												darkHover: '/images/Header/dark-hover-external-link.svg',
												light: '/images/Header/light-external-link.svg',
												dark: '/images/Header/dark-external-link.svg',
											}}
											size={17}
										/>
									</a>
								</Link>
							</div>
							<DropdownRow
								image={{
									lightHover: '/images/Header/hover-disconnect-gnosis-safe.svg',
									darkHover: '/images/Header/hover-disconnect-gnosis-safe.svg',
									light: '/images/Header/light-disconnect-gnosis-safe.svg',
									dark: '/images/Header/dark-disconnect-gnosis-safe.svg',
								}}
								text="Disconnect"
								gnosis
								onClick={removeGnosis}
							/>
						</>
					) : (
						<>
							<DropdownRow
								image={{
									lightHover: '/images/Header/hover-connect-gnosis-safe.svg',
									darkHover: '/images/Header/hover-connect-gnosis-safe.svg',
									light: '/images/Header/light-connect-gnosis-safe.svg',
									dark: '/images/Header/dark-connect-gnosis-safe.svg',
								}}
								text="Connect"
								onClick={onGnosisModalOpen}
								gnosis
							/>
							<DropdownRow
								image={{
									lightHover: '/images/Wallets/hover-gnosis-safe.svg',
									darkHover: '/images/Wallets/hover-gnosis-safe.svg',
									light: '/images/Wallets/light-gnosis-safe.svg',
									dark: '/images/Wallets/dark-gnosis-safe.svg',
								}}
								size={17}
								text="What is Gnosis Safe?"
								href="https://gnosis-safe.io/"
								openExternal
								gnosis
							/>
						</>
					)}
				</MenuList>
			</Menu>
		</>
	)
}
